/* Max Widths */
.max-w-200px {
  max-width: 200px !important;
}

.max-w-304px {
  max-width: 304px !important;
}

.max-w-319px {
  max-width: 319px !important;
}

.max-w-332px {
  max-width: 332px !important;
}

.max-w-414px {
  max-width: 414px !important;
}

.max-w-500px {
  max-width: 500px !important;
}

.max-w-585px {
  max-width: 585px !important;
}

.max-w-608px {
  max-width: 608 !important;
}

.max-w-633px {
  max-width: 633px !important;
}

.max-w-636px {
  max-width: 636px !important;
}

.max-w-743px {
  max-width: 743px !important;
}

.max-w-852px {
  max-width: 852px !important;
}

.max-w-902px {
  max-width: 902px !important;
}

/* Max Heights */
.max-h-200px {
  max-height: 200px !important;
}

.max-h-300px {
  max-height: 300px !important;
}

.max-h-400px {
  max-height: 400px !important;
}

.max-h-500px {
  max-height: 500px !important;
}

/* Min Widths */

.min-w-100px {
  min-width: 100px !important;
}

/* Widths */

.w-100percent {
  width: 100% !important;
}

.w-10px {
  width: 10px !important;
}

.w-15px {
  width: 15px !important;
}

.w-20px {
  width: 20px !important;
}
.w-30px {
  width: 30px !important;
}

.w-35px {
  width: 35px !important;
}

.w-44px {
  width: 44px !important;
}

.w-50px {
  width: 50px !important;
}

.w-60px {
  width: 60px !important;
}

.w-70px {
  width: 70px !important;
}

.w-100px {
  width: 100px !important;
}

.w-120px {
  width: 120px !important;
}

.w-124px {
  width: 124px !important;
}

.w-130px {
  width: 130px !important;
}

.w-150px {
  width: 150px !important;
}

.w-195px {
  width: 195px !important;
}

.w-200px {
  width: 200px !important;
}

.w-250px {
  width: 250px !important;
}

.w-289px {
  width: 289px !important;
}

.w-220px {
  width: 220px !important;
}

.w-303px {
  width: 303px !important;
}

.w-304px {
  width: 304px !important;
}

.w-332px {
  width: 332px !important;
}

/* Heights */

.h-14px {
  height: 14px !important;
}
.h-20px {
  height: 20px !important;
}

.h-32px {
  height: 32px !important;
}
.h-35px {
  height: 35px !important;
}

.h-51px {
  height: 51px !important;
}

.h-63px {
  height: 63px !important;
}

.h-81px {
  height: 81px !important;
}

.h-96px {
  height: 96px !important;
}

.h-150px {
  height: 150px !important;
}

.h-221px {
  height: 221px !important;
}

.h-min-60px {
  min-height: 60px !important;
}

.h-240px {
  height: 240px !important;
}

.h-121px {
  height: 121px;
}

.h-249px {
  height: 249px !important;
}

.h-284px {
  height: 284px !important;
}

.h-723px {
  height: 723px !important;
}

.h-auto {
  height: auto;
}
