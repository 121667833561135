.display-flex {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-flex-end {
  justify-content: flex-end !important;
}

.justify-content-spacebetween {
  justify-content: space-between !important;
}

.justify-content-flex-start {
  justify-content: flex-start !important;
}

.justify-content-flex-end {
  justify-content: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-flex-end {
  align-items: flex-end;
}
