.fs-6px {
  font-size: 6px !important;
}

.fs-8px {
  font-size: 8px !important;
}

.fs-10px {
  font-size: 10px !important;
}

.fs-11px {
  font-size: 11px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-15px {
  font-size: 15px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-18px {
  font-size: 18px !important;
}

.fs-20px {
  font-size: 20px !important;
}

.fs-22px {
  font-size: 22px !important;
}

.fs-24px {
  font-size: 24px !important;
}

.fs-26px {
  font-size: 26px !important;
}

.fs-32px {
  font-size: 32px !important;
}

.fs-36px {
  font-size: 36px !important;
}
